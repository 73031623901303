
@import '~bootstrap/scss/bootstrap.scss';
// @import '~flatpickr/dist/themes/light.css';
@import './painelConsulta.scss';
@import './elementos/tabela.scss';
@import './elementos/asyncSelect.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
// @import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '~react-toastify/dist/ReactToastify.css';
// /node_modules/react-grid-layout/css/styles.css
// /node_modules/react-resizable/css/styles.css
// @import '~react-grid-layout/css/styles.css';
// @import '~react-resizable/css/styles.css';
// @import '~react-calendar/dist/Calendar.css';

html {
    background-color: rgba(203,203,210,0.15);
}

.centro-container {
    align-self: center;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
}

.form-control, .btn {

    &:active, &:focus {
        outline: 0;
        box-shadow: none;
    }

    // se possuir icone deixa um espaçamento
    .icone + span, span + .icone {
        margin-left: 5px;
    }

    &.btn-padrao {
        background: #fafafa;
        box-shadow: 1px 1px 3px #b2bec3;
        color: #707070;
        font-weight: bold;
    
        &:hover {
            background: #f0f0f0;
            box-shadow: 1px 1px 3px #b9b9b9;
        }
    
        &:not(:disabled):not(.disabled):active:focus {
            background: rgb(211, 211, 211);
            // box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5)
            box-shadow: 0px 0px 4px #bbbbbb;
        }
    
    }
    
    &.btn-padrao-laranja {
        background: #f9a827;
        box-shadow: 1px 1px 3px #b19469;
        color: #4e4e4e;
        font-weight: bold;
    
        &:hover {
            background: #f0a224;
            box-shadow: 1px 1px 3px #b9b9b9;
        }
    
        &:not(:disabled):not(.disabled):active:focus {
            background: #f7c472;
            // box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5)
            box-shadow: 0px 0px 4px #bbbbbb;
        }

    }

    &.btn-success {
        font-weight: bold;
        background: #28a745;
        box-shadow: 1px 1px 3px #b2bec3;

        &:hover {
            background: #23943e;
            box-shadow: 1px 1px 3px #b9b9b9;
        }

        &:not(:disabled):not(.disabled):active:focus {
            background: #1c7430;
            box-shadow: 0px 0px 4px rgba(72, 180, 97, 0.5);
        }
    }

    &.btn-danger {
        font-weight: bold;
        background: #bd2130;
        box-shadow: 1px 1px 3px #b2bec3;

        &:hover {
            background: #af1f2d;
            box-shadow: 1px 1px 3px #b9b9b9;
        }

        &:not(:disabled):not(.disabled):active:focus {
            background: #9e1c29;
            box-shadow: 0px 0px 4px rgba(225, 83, 97, 0.5);
        }
    }

    &.btn-primary {
        box-shadow: 1px 1px 3px #b2bec3;
        font-weight: bold;
        
        &:hover {
            box-shadow: 1px 1px 3px #b9b9b9;
        }

        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: 0px 0px 4px rgba(83, 104, 225, 0.5);
        }
    }

    &.btn-amarelo {
        background: #ccbe26;
        box-shadow: 1px 1px 3px #b2bec3;
        color: white;
        font-weight: bold;
        text-shadow: 0 0 6px #a5a5a5;
    
        &:hover {
            background: #bdb023;
            box-shadow: 1px 1px 3px #b9b9b9;
        }
    
        &:not(:disabled):not(.disabled):active:focus {
            background: #afa320;
            // box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5)
            box-shadow: 0px 0px 4px #bbbbbb;
        }

    }

    &.btn-info {
        box-shadow: 1px 1px 3px #b2bec3;
        font-weight: bold;
        
        &:hover {
            box-shadow: 1px 1px 3px #b9b9b9;
        }

        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: 0px 0px 4px rgba(83, 104, 225, 0.5);
        }

    }
    
    &.btn-warning {
        // background: #ccbe26;
        box-shadow: 1px 1px 3px #b2bec3;
        // color: white;
        font-weight: bold;
        // text-shadow: 0 0 6px #a5a5a5;
    
        &:hover {
            // background: #bdb023;
            box-shadow: 1px 1px 3px #b9b9b9;
        }
    
        &:not(:disabled):not(.disabled):active:focus {
            // background: #afa320;
            // box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5)
            box-shadow: 0px 0px 4px #bbbbbb;
        }

    }

}

.modal-no-background-content{
    .modal-content{
        background: none;
        border: none;
    }
}
